<template>
  <div id="UserOperatePC">
    <div shadow="never" class="TopCardContainer">
      <el-card shadow="never" class="SearchCardContainer">
        <el-input
          class="searchInput"
          v-model="searchInput"
          placeholder="输入查找用户ID"
          @change="searchInputChange"
        >
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </el-card>
    </div>

    <div class="BottomContainer">
      <el-table
        tooltip-effect="dark"
        style="width: 100%"
        :data="tableData"
        :max-height="countHeight"
      >
        <el-table-column align="center" label="加入日期" width="200">
          <template slot-scope="scope">{{ scope.row.created_at.split(' ')[0] }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="account_id"
          label="用户ID"
          width="200"
        ></el-table-column>
        <el-table-column align="center" prop="type" label="身份">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 'supporter'">客服</span>
            <span v-else>卖家</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="总销售金额">
          <template slot-scope="scope">
            <span style="letter-spacing: 1.2px; white-space: nowrap">￥{{ scope.row.total }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="tmp_password" label="暂时密码" width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.has_change_pwd === 0">
              {{ scope.row.tmp_password }}
            </div>
            <div v-else> 已修改过 </div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="其它功能" width="220" fixed="right">
          <template slot-scope="scope">
            <div class="OptionBlock">
              <el-button plain @click="modifyHandler(scope.row)">修改密码</el-button>
              <el-button plain @click="searchHandler(scope.row)">查詢訂單</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="sizes,prev, pager, next, jumper"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.PAGE_SIZE"
        :page-count="pageData.totalPages"
        :total="pageData.count"
        @size-change="pageSizeChange"
        @current-change="initAPI"
      >
      </el-pagination>
    </div>

    <el-dialog title="修改密码" :visible.sync="centerDialogVisible" width="400px" center>
      <el-form
        v-if="this.centerDialogVisible"
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="ruleForm"
      >
        <el-form-item label="新密码" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item class="submitBtn">
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import mixin from './UserOperateMixin';
  export default {
    extends: mixin,
    name: 'UserOperatePC',
    methods: {
      searchInputChange() {
        this.pageData.currentPage = 1;
        this.initAPI();
      },
    },
  };
</script>

<style lang="scss">
  @import '@/views/Console/Style/TopCardStyle.scss';
  #UserOperatePC {
    .ruleForm {
      .el-form-item {
        &:last-child {
          margin-top: 35px;
          margin-bottom: 0px;
        }
        .el-form-item__content {
          margin: 0 !important;

          display: flex;
          justify-content: center;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
  #UserOperatePC {
    .ruleForm {
      .submitBtn {
        .el-form-item__content {
          margin: 0 auto !important;
        }
      }
    }
  }
</style>
