<template>
  <div id="UserOperateMobile" ref="UserOperateMobile">
    <el-input
      class="searchInput"
      v-model="searchInput"
      placeholder="输入查找用户ID"
      @change="initAPI(true)"
    >
      <el-button slot="append" icon="el-icon-search"></el-button>
    </el-input>
    <div class="splitLine"> </div>
    <div class="CardItem" v-for="(it, index) in tableData" :key="index">
      <div class="CardContainer">
        <div class="CardDataRow">
          <div class="CardTitle"> 加入日期 </div>
          <div class="CardVal"> {{ it.created_at.split(' ')[0] }}</div>
          <div class="CardLine"></div>
          <div class="CardTitle"> 用户ID </div>
          <div class="CardVal"> {{ it.account_id }}</div>
          <div class="CardLine"></div>
          <div class="CardTitle"> 身份 </div>
          <div class="CardVal"> {{ it.type === 'supporter' ? '客服' : '卖家' }}</div>
          <div class="CardLine"></div>
          <div class="CardTitle"> 总销售金额 </div>
          <div class="CardVal"> {{ it.total }}</div>
          <div class="CardLine"></div>
          <div class="CardTitle"> 暂时密码 </div>
          <div class="CardVal" v-if="it.has_change_pwd === 0"> {{ it.tmp_password }}</div>
          <div class="CardVal" v-else> 已修改过</div>
          <div class="CardLine"></div>
          <div class="otherOptionRow">
            <div class="CardTitle"> 其他功能 </div>
            <div class="CardValRow">
              <div class="CardBtn" @click="modifyHandler(it)">修改密码</div>
              <div class="CardBtn" @click="searchHandler(it)">查詢訂單</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="LastTip" v-if="isNoMoreData"> 已无更多资料 </div>
    <el-dialog title="修改密码" :visible.sync="centerDialogVisible" width="90%" center>
      <el-form
        v-if="this.centerDialogVisible"
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="ruleForm"
      >
        <el-form-item label="新密码" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item class="submitBtn">
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import mixin from './UserOperateMixin';
  export default {
    extends: mixin,
    name: 'UserOperateMobile',
    mounted() {
      this.$refs.UserOperateMobile.addEventListener('scroll', (event) => {
        const element = event.target;
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
          if (this.pageData.currentPage < this.pageData.totalPages) {
            this.pageData.currentPage++;
            this.initAPI();
          }
        }
      });
    },
    computed: {
      isNoMoreData() {
        return this.pageData.currentPage === this.pageData.totalPages;
      },
    },
    methods: {
      initAPI(isSearchChange = false) {
        if (isSearchChange) {
          this.pageData.currentPage = 1;
        }
        this.$store.commit('setLoading', true);
        this.$store
          .dispatch('admin/user/getAccounts', {
            page: this.pageData.currentPage,
            pageSize: this.pageData.PAGE_SIZE,
            search: this.searchInput,
          })
          .then((res) => {
            this.pageData.currentPage = res.currentPage;
            this.pageData.totalPages = res.totalPages;
            this.pageData.count = res.count;
            if (isSearchChange) {
              this.tableData.length = 0;
            }
            this.tableData.push(...res.data);
          })
          .catch(this.$autoCatch)
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  #UserOperateMobile {
    height: 100%;
    overflow-y: auto;
    padding-right: 5px;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: rgba(0, 0, 0, 0.3);
    }
    .splitLine {
      width: 100%;
      height: 1px;
      margin: 20px 0;
      background-color: #cdd0d8;
    }
    .CardItem {
      border: 1px solid #cdd0d8;
      color: #6e6e6e;
      border-radius: 2px;
      font-size: 14px;
      margin-bottom: 10px;
      .CardContainer {
        padding: 16px 30px;
        .CardDataRow {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .CardTitle {
            width: 100px;
            display: flex;
            align-items: center;
          }
          .CardVal {
            width: calc(100% - 100px);
            color: #818080;
          }
          .CardLine {
            width: 100%;
            height: 1px;
            background-color: rgba(128, 128, 128, 0.189);
            margin: 10px 0;
            transform: scaleX(1.05);
          }
          .otherOptionRow {
            display: flex;
            align-items: center;
          }
          .CardValRow {
            display: flex;
            flex-wrap: wrap;
            .CardBtn {
              padding: 8px 10px;
              color: #818080;
              border: 1px solid #cdd0d8;
              border-radius: 3px;
              margin-left: 10px;
              margin-top: 5px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
    .LastTip {
      font-size: 18px;
      text-align: center;
      font-weight: bold;
      padding: 25px 0;
      color: #6e6e6e;
    }
  }
</style>
