<template>
  <div id="UserOperate">
    <template v-if="isMobile">
      <UserOperateMobile> </UserOperateMobile>
    </template>
    <template v-else>
      <UserOperatePC> </UserOperatePC>
    </template>
  </div>
</template>

<script>
  import UserOperatePC from './UserOperatePC.vue';
  import UserOperateMobile from './UserOperateMobile.vue';
  export default {
    name: 'UserOperate',
    components: {
      UserOperatePC,
      UserOperateMobile,
    },
    computed: {
      isMobile() {
        return this.$store.state.isMobile;
      },
    },
  };
</script>

<style lang="scss">
  @import '@/views/Console/Style/TopCardStyle.scss';
  #UserOperate {
    height: 100%;
    .ruleForm {
      .el-form-item {
        &:last-child {
          margin-top: 35px;
          margin-bottom: 0px;
        }
        .el-form-item__content {
          margin: 0 !important;

          display: flex;
          justify-content: center;
        }
      }
    }
  }
</style>
