import AutoCountTableHeight from '@/views/Console/Base/AutoCountTableHeight.js';

const UserOperateMixin = {
  mixins: [AutoCountTableHeight],
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      Base_offset: 230,
      searchInput: '',
      tableData: [],
      pageData: {
        currentPage: 1,
        totalPages: 1,
        count: 0,
        PAGE_SIZE: 10,
      },
      centerDialogVisible: false,
      ruleForm: {
        pass: '',
        checkPass: '',
      },
      rules: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }],
      },
      modifyID: null,
    };
  },
  created() {
    this.initAPI();
  },
  methods: {
    initAPI() {
      this.$store.commit('setLoading', true);

      this.$store
        .dispatch('admin/user/getAccounts', {
          page: this.pageData.currentPage,
          pageSize: this.pageData.PAGE_SIZE,
          search: this.searchInput,
        })
        .then((res) => {
          this.pageData.currentPage = res.currentPage;
          this.pageData.totalPages = res.totalPages;
          this.pageData.count = res.count;
          this.tableData = res.data;
        })
        .catch(this.$autoCatch)
        .finally(() => {
          this.$store.commit('setLoading', false);
        });
    },
    modifyHandler(data) {
      this.ruleForm.pass = '';
      this.ruleForm.checkPass = '';
      this.centerDialogVisible = true;
      this.modifyID = data.id;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.commit('setLoading', true);
          this.$store
            .dispatch('admin/user/supportChangePWD', {
              id: this.modifyID,
              new_password: this.ruleForm.pass,
              confirm_password: this.ruleForm.checkPass,
            })
            .then((res) => {
              this.centerDialogVisible = false;
              this.initAPI(true);
              this.$MSG.success(res.message);
            })
            .catch(this.$autoCatch)
            .finally(() => {
              this.$store.commit('setLoading', false);
            });
        } else {
          return false;
        }
      });
    },
    pageSizeChange(val) {
      this.pageData.PAGE_SIZE = val;
      this.initAPI();
    },
    searchHandler(clickRow) {
      this.$router.push({
        name: 'ConsoleOrderManagement_SellOrder',
        params: { uid: clickRow.account_id },
      });
    },
  },
};

export default UserOperateMixin;
